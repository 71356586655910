<template>
  <div>
    <div class="truckCtrl">
      <b-button class="chartBtn" v-b-tooltip.hover :title="tooltiptext"
        >{{ ganttChartVehicle.vehicleName }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    /**
     * Vehicle情報.
     * @type {Object}
     * @requires
     */
    ganttChartVehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltiptext() {
      return ''
      // return (
      //   '車型：' +
      //   (this.ganttChartVehicle.vehicleModelName || '') +
      //   '\n車両名称：' +
      //   (this.ganttChartVehicle.vehicleName || '') +
      //   '\n所属拠点：' +
      //   (this.ganttChartVehicle.vehicleBasePlaceName || '') +
      //   '\n積載質量(kg)：' +
      //   (this.ganttChartVehicle.maxLoadingWeight || '')
      // )
    },
  },
}
</script>
<style scoped>
.truckCtrl {
  width: 160px;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-top: 80px;
}
.chartBtn {
  width: 120px;
  height: 50px;
  vertical-align: middle;
  background-color: wheat;
  color: black;
  text-align: left;
  margin-bottom: 5px;
  align-items: center;
  float: none;
  font-size: 0.8rem;
}
</style>
